@import "~bootstrap/scss/bootstrap";
@import url('https://fonts.googleapis.com/css2?family=Courgette&family=Petit+Formal+Script&family=Poppins:wght@300&family=Tenor+Sans&family=Yeseva+One&display=swap');
* {
  // font-family: 'Open Sans', sans-serif;
  // font-family: 'Tajawal', sans-serif;
  // font-family: 'Roboto Slab', serif;
  // font-family: 'Spectral', serif;
    font-family: 'Tenor Sans', serif;
  // font-family: 'Poppins', sans-serif;
}

div.container {
  padding: 0 !important;
}

button {
  background-color: 'white' !important;
  border: none;
}

//! Scroll offset 
#thaiYoga,
#thaiFoot,
#swedishMassage,
#deepTissue,
#manualLymph,
#thaiPregnancy,
#naturalFacial,
#bamboo,
#hotAndCold,
#myofascial,
#lomi {
  scroll-margin-top: 15vh;
}

//! Navbar
nav {
  height: 10vh;
  background-color: white;
}

.nav-logo {
  height: 0px;
}

a.nav-link {
  color: #0C3751 !important;
  font-weight: 500;
  font-size: 2.3vh;
  margin-left: 1vw;
}

.btn-group {
  color: #0C3751 !important;
  font-weight: 500;
  font-size: 2.3vh !important;
  margin-left: 1vw;
}

.btn-primary {
  background-color: #66a1a8 !important;
  border: none !important;
}

#nav-button {
  color: white !important;
  padding: 1vh 2vw;
}

.transparentise {
  background-color: transparentize($color: white, $amount: 0.05);
}

.btn:focus {
  outline: none !important;
  box-shadow: none !important;
}

.active {
  background: #66a1a8 !important;
}

.btn-none {
  color: #0C3751 !important;
  font-weight: 500 !important;
  font-size: 2.3vh !important;
}

#bg-nested-dropdown {
  padding: 0;
  margin: 0;
}

//! Footer
.tcLink {
  text-decoration: none;
  color: white;
}

.tcLink:hover {
  text-decoration: none;
  color: white;
}

//!Homepage
.homepage-image {
  height: 90vh;
  max-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -30vh;
  transform: scaleX(-1);
}

.plant-image {
  height: 90vh;
  max-width: 100vw;
  background-size: cover;
  background-repeat: no-repeat;
  background-position-y: -30vh;
  transform: scaleX(-1);
}

div.col {
  padding: 0;
}

.shadow {
  display: flex;
  position: absolute;
  width: 100vw;
  height: 90vh;
  background-color: transparentize($color: grey, $amount: 0.6);
  z-index: 5;
}

.homepage-text {
  height: 30vh;
  text-align: center;
  font-size: 3vh;
  font-weight: lighter;
  color: white;
  z-index: 6;
  transform: scaleX(-1);
}

.homepage-plant-text {
  height: 100%;
  text-align: center;
  font-size: 3vh;
  font-weight: lighter;
  color: #0C3751;
  z-index: 6;
  transform: scaleX(-1);
  display: flex;
}
.plant-text {
  justify-self: flex-start;
  align-self: center;
  margin-left: 10vw;
}
.homepage-book-button {
  margin-top: 1vh;
  color: white !important;
}

.homepage-paragraph {
  height: 50vh;
  padding: 12vh 5vw 4vh;
  font-size: 2.5vh;
  text-align: center;
  color: #0C3751;
  font-weight: bolder;
}
.quote {
  font-family: 'Tenor-Sans';
  color: #0C3751;
}

//! About
.about-image {
  z-index: 6;
  height: 50vh;
}

.about-image-container {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.about-text-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.green-background {
  background-color: #0C3751;
}

.qualificationsButton {
  border: solid 4px #66a1a8 !important;
  width: 25vw;
  margin: 2vh auto;
}
//! Treatments
.yoga-image {
  width: 30vw;
  margin: 5vh 0;
}

.footer-button {
  background-color: white !important;
  color: #0C3751 !important;
}

.treatment-descriptions {
  text-align: left;
  border: none !important;
}

.treatment-readmores {
  margin-top: -1.5rem;
}

.tcDiv {
  width: 50vw;
  font-size: 18px;
  margin: 0 auto;
  text-align: left;
}

.tcSubContainer {
  text-align: left;
  width: 50vw;
  margin: 0 auto;
}

.tcHeading {
  margin: 0;
  font-size: 4vh;
  text-align: left;
  padding: 3vh 0 1vh;
  margin-Bottom: 1vh;
  color: #66a1a8;
}

.insuranceLogo {
  width: 11%;
  padding: 0 1vw 0 1vw;
  background: white;
}

//! Gut Health
.gutHealthPara {
  width: 75vw;
  margin: 0 auto;
  font-size: 18px;
  text-align: left;
  border: none !important;
  line-height: unset !important;
}

.gutHealthParaBold {
  font-weight: bolder;
  font-size: 19px;
}

.productTitle {
  font-size: 20px;
  margin-top: 5vh;
}

.productImage {
  height: 60vh;
}

.productWrapper {
  display: flex;
  align-items: center;
}

.guth6 {
  margin: 0vh auto 0;
  color: black;
  padding: 2vh 20px 0;
  font-size: 25px;
  font-weight: bolder;
  text-align: left;
  width: 75vw;
}

.firsth6 {
  margin-top: 10vh;
  color: black;
  padding: 2vh 20px 0;
  font-size: 25px;
  font-weight: bolder;
  text-align: left;
  width: 75vw;
}

.gutHealthLinks {
  color: #66a1a8;
}

//! Gallery 
.carouselContainer {
  background: transparentize($color: grey, $amount: 0.95);
  display: flex;
  width: 85vw;
  height: 80vh;
  align-items: center;
  justify-content: center;
}
.carouselImagesContainer {
  height: fit-content;
  display: flex;
  align-items: center;
  overflow: hidden;
}

.tilesContainer {
  display: flex;
  width: 50vw;
  margin: 0 auto;
  justify-content: space-evenly;
  flex-wrap: wrap;
}

.tile {
  height: 15vh;
  width: 15vh;
  margin: 1.5vh;
  overflow: hidden;
}

.tile2 {
  height: 15vh;
  margin-left: -5vh;
}

.tile3 {
  height: 15vh;
  margin-left: -3vh;
}

.tile4 {
  height: 15vh;
  margin-left: -3vh;
}

.tile5 {
  height: 15vh;
  margin-left: -3vh;
}

.tile6 {
  width: 15vh;
}

.tile7 {
  width: 15vh;
}

.tile8 {
  width: 15vh;
}

.pImg {
  width: 50vw !important;
}
.hImg:hover {
  background: transparentize($color: grey, $amount: 0.5);
}
.hImg {
  height: 50vh !important;
}

.tile1 {
  height: 15vh;
  margin-left: -3.5vh;
}
.tile6 {
  margin-top: -3vh;
}

.tile7 {
  margin-top: -0.5vh;
}
.tile14 {
  width: 15vh !important;
  margin-top: -4vh;
}

.tile15 {
  width: 15vh !important;
}

.tile16 {
  height: 15vh;
  margin-left: -1.5vh;
}
.img1 {
  // margin-Top: -17vh !important;
}

.img2 {
    // margin-Top: -40vh !important;
}

.img3 {
    // margin-Top: -35vh !important;
}
// .carousel-item {
//   height: 80%;
//   overflow: hidden;
// }
.carousel-caption {
  background: transparentize($color: grey, $amount: 0.85) !important;
  // margin-top: 10vh;
}

.active {
  background-color: #1a1919 !important;
  // background-color: #ffffff !important;
}
.carousel-control-prev-icon,
.carousel-control-next-icon {
  background: none;
}

.carousel-indicators li {
  background-color: none;
  border-top: none;
  border-bottom: none;
}
.leftArrow {
  height: 2.5vh;
  transform: rotate(180deg);
  margin: 5vh;
}

.rightArrow {
  height: 2.5vh;
  margin: 5vh;
}
//! Responsive 

@media screen and (min-width: 600px) {
  .homepage-text {
    padding-right: 60vw;
      padding-bottom: 40vh;
    }
}

@media only screen and (max-width: 768px) {
  .renata-image {
      width: 80vw !important;
    }
  .qualificationsButton {
      width: 80vw;
    }
  .quote {
      width: 80vw;
      font-family: 'Tenor-Sans';
    }
  .tilesContainer {
      width: 80vw;
      margin-top: 30vh;
    }
  
    .leftArrow,
    .rightArrow {
      margin: 0.5vh;
    }
  
    // .slide {
    //   height: 50vh;
    // }
  
    .carouselContainer {
      width: 90vw;
    }
    // .img2 {
    //     height: 70vh;
    //     width: unset !important;
    //     margin-left: -110vw !important;
    //     margin-top: -20vh !important;
    //   }
  
    //   .img1 {
    //     height: 60vh;
    //     width: unset !important;
    //     margin-left: 15vw !important;
    //   }
  
    //   .img3 {
    //     height: 60vh;
    //     width: unset !important;
    //     margin-top: -20vh !important;
    //     margin-left: -90vw !important;
    //   }
  .homepage-image {
    background-position-x: -80vw;
    background-position-y: 0vh;
  }
.plant-image {
  background-position-y: 0vh;
  background-position-x: -30vw;
}

.plant-text {
  margin: 10vh auto;
  align-self: flex-start;
  justify-self: center;
}
  .stars {
    font-size: 10px !important;
  }

  .tcDiv {
    width: 90vw;
  }

  .tcSubContainer {
    width: 90vw;
  }

  .insuranceLogo {
    width: 35%;
  }

  .guth6 {
    width: 90vw;
    margin: 0vh auto 0;
  }

  .firsth6 {
    width: 90vw;
    margin: 4vh auto 0;
  }

  .gutHealthPara {
    width: 90vw;
    line-height: unset;
  }

  .productImage {
    height: 50vh;
  }

                                                                                                                                                                                                                                                                .twentyOneDayTitle {
                                                                                                                                                                                                                                                                  width: 90vw;
      margin: 0 auto !important;
    }
}
